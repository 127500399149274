import React, { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import axios from 'axios'
import { useChannel } from '@harelpls/use-pusher';

const API_BASE = import.meta.env.VITE_API_BASE;

interface AuthContent {
	token   : string,
	user    : any,
	setUser : React.Dispatch<any>,
	isReady : boolean,

	hasPermission   : (permission   : string) => boolean,
	isAuthenticated : () => boolean,
	
	login  : (credentials : any) => Promise<unknown>,
	logout : (remotely    : boolean) => Promise<void>,

	publishers           : any[],
	updatePublisherTerms : (publisherId : any, terms : any) => void,
}
const AuthContext = createContext({} as AuthContent);

const AuthProvider = ({ children }) => {
    const [token, setToken] = useState(null)
    const [user, setUser] = useState(null)
    const [isReady, setIsReady] = useState(false);

    useEffect(() => {
        let localToken = localStorage.getItem('token');

        if (localToken && !user) {
            // validate user and token here
            axios.get(API_BASE + 'user', {
                headers: {
                    Authorization: 'Bearer ' + localToken
                }
            }).then(result => {
                setToken(localToken);
                setUser(result.data);
                setIsReady(true);
            }).catch(e => {
                if (e.response.status === 401) {
                    localStorage.removeItem('token');
                    setIsReady(true)
                }
            });
        } else {
            setIsReady(true);
        }
    }, [user])

    const hasPermission = useCallback((permission) => {
        if (!user) {
            return false;
        }

        return user.permissions.includes(permission);
    }, [user]);
    
    const isAuthenticated = useMemo(() => token && user, [token, user]);

    const login = (credentials) => new Promise((resolve, reject) => {
        axios.post(API_BASE + 'login', {
            ...credentials,
            device_name: 'test'
        }).then(result => {
            setToken(result.data.token);
            
            axios.get(API_BASE + 'user', {
                headers: {
                    Authorization: 'Bearer ' + result.data.token
                }
            }).then(result => {
                setUser(result.data);
            });

            localStorage.setItem('token', result.data.token);

            resolve(user);
        }).catch(e => {
            reject(e);
        })
    })

    const logout = async (remotely = true) => {
        setToken(null);
        setUser(null);
        localStorage.removeItem('token');

        if (remotely) {
            await axios.post(API_BASE + 'logout', {}, {
                headers: {
                    Authorization: 'Bearer ' + token
                }
            });
        }
    };

    const publishers = useMemo(() => {
        if (!user) {
            return [];
        }

        return user.publishers.sort((a, b) => {
            if (a.name.toLowerCase() < b.name.toLowerCase()) {
                return -1;
            }

            return 1;
        });
    }, [user]);

    const updatePublisherTerms = (publisherId, terms) => {
        setUser({
            ...user,
            publishers: user.publishers.map(publisher => {
                if (publisher.id === publisherId) {
                    return {
                        ...publisher,
                        terms
                    };
                }

                return publisher;
            })
        });
    };

    return (
        <AuthContext.Provider value={{ isReady, user, setUser, publishers, isAuthenticated, login, logout, updatePublisherTerms, token, hasPermission }}>
            { children }
        </AuthContext.Provider>
    );
};

const useAuth = () => React.useContext(AuthContext);

export { AuthProvider, useAuth }